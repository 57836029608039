import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import Header5 from '../layout/header5';
import Footer6 from '../layout/footer6';

const bg = require('../../images/banner/bnr1.jpg')

class PrivacyPolicy extends Component {


    render() {
        return (
            <>
                <Header5/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" , paddingTop : 100 }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Privacy Policy</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Privacy Policy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="content-block">
                        <div className="section-full content-inner inner-text">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h4 className="title">Introduction</h4>
                                        <p className="font-18">Welcome to <b>SCT</b> ("we," "us," or "our"). We value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [yourwebsite.com] (the "Site") and use our services. Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Site.</p>
                                        <div className="dlab-divider bg-gray-dark"></div>
                                        <h4 className="title">Information We Collect and Use of Data</h4>
                                        <h5 className="title">Personal Data</h5>
                                        <p className="font-18">When you use our Site, we may collect personally identifiable information ("Personal Data") that can be used to contact or identify you. This includes, but is not limited to: Name, Email address, Phone number, and Any other information you choose to provide.</p>

                                        <h5 className="title">Usage Data</h5>
                                        <p className="font-18">We may also collect information that your browser sends whenever you visit our Site or when you access the Site by or through a mobile device ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol (IP) address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>

                                        <h5 className="title">Use of Data</h5>
                                        <p className="font-18">We use the collected data for various purposes, including: To provide and maintain our Site, To notify you about changes to our Site, To allow you to participate in interactive features of our Site when you choose to do so, To provide customer support, To gather analysis or valuable information so that we can improve our Site, To monitor the usage of our Site, To detect, prevent, and address technical issues, To fulfill any other purpose for which you provide it, and To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.</p>

                                        <h5 className="title">Disclosure of Data</h5>
                                        <p className="font-18">We may disclose personal information that we collect, or you provide: To comply with any court order, law, or legal process, including responding to any government or regulatory request, To enforce.</p>

                                        {/* <h3 className="title">Personal Data</h3>
                                        <p className="font-18"></p>

                                        <div className="dlab-divider bg-gray-dark"></div>
                                        <h4 className="title">What personal information we collect</h4>
                                        <ul className="list-circle primary m-a0">
                                            <li>The Industrial Privacy Policy was updated on 25 June 2018. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</li>
                                            <li>Remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</li>
                                            <li>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</li>
                                            <li>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer6/>                
            </>
        )
    }
}
export default PrivacyPolicy;