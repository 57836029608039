import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import Header5 from '../layout/header5';
import Footer6 from '../layout/footer6';

const bg = require('../../images/banner/bnr1.jpg')


class CaseStudies extends Component {


    render() {
        return (
            <>
                <Header5/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" , paddingTop : 100 }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Case Studies</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Case Studies</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <iframe src='https://drive.google.com/file/d/16vKOXV9E-M8L4g2uWBuWTywtMFRg_7j1/preview' width='100%' height='6000px' frameborder='0' />
                </div>                
                <Footer6/>                
            </>
        )
    }
}
export default CaseStudies;