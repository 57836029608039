import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import Header5 from '../layout/header5';
import Footer6 from '../layout/footer6';

const bg = require('../../images/background/bg5.jpg');

class Contact1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            message: ''
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { name, phone, email, message } = this.state;

        // EmailJS service parameters
        const serviceID = 'service_8yci1kc';
        const templateID = 'template_jlbfca7';
        const userID = 'nsceNYeG0ioF47_Do';

        const templateParams = {
            name: name,
            phone: phone,
            email: email,
            message: message
        };

        emailjs.send(serviceID, templateID, templateParams, userID)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Message successfully sent!');
                this.setState({
                    name: '',
                    phone: '',
                    email: '',
                    message: ''
                });
            })
            .catch((err) => {
                console.error('FAILED...', err);
                alert('Message failed to send.');
            });
    }

    render() {
        return (
            <>
                <Header5/>
                <div className="page-content bg-white" style={{paddingTop:125}}>
                    <div className="section-full content-inner contact-page-9 overlay-black-dark" style={{ background: "url(" + bg + ") 30% 100%"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-12 text-white">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 m-b30">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary"><i className="ti-location-pin"></i></span> 
                                                        Company Address
                                                    </h5>
                                                    <p>2878 River Meadow Circle, Canton, Michigan - 48188</p>
                                                    <h6 className="m-b15 font-weight-400"><i className="ti-alarm-clock"></i> Office Hours</h6>
                                                    <p className="m-b0">Mon To Fri : 08:00 AM - 05:00 PM</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary"><i className="ti-email"></i></span> 
                                                        E-mail
                                                    </h5>
                                                    <p className="m-b0">info@sctglobalinc.com</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary"><i className="ti-mobile"></i></span> 
                                                        Phone Numbers
                                                    </h5>
                                                    <p className="m-b0"> +1 (313) 461-9943</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12 m-b30">
                                    <form className="inquiry-form wow box-shadow bg-white fadeInUp" data-wow-delay="0.2s" onSubmit={this.handleSubmit}>
                                        <h3 className="title-box font-weight-300 m-t0 m-b10">Let's Convert Your Idea into Reality <span className="bg-primary"></span></h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the</p>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input name="name" type="text" required className="form-control" placeholder="Your Name" value={this.state.name} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                        <input name="phone" type="text" required className="form-control" placeholder="Phone" value={this.state.phone} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="input-group"> 
                                                        <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                        <input name="email" type="email" className="form-control" required placeholder="Your Email Id" value={this.state.email} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                        <textarea name="message" rows="4" className="form-control" required placeholder="Tell us about your project or requirement" value={this.state.message} onChange={this.handleChange}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <button name="submit" type="submit" value="Submit" className="site-button button-md"> <span>Get A Free Quote!</span> </button>
                                            </div>
                                        </div>
                                    </form>	
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer6/>                
            </>
        );
    }
}

export default Contact1;
