import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../layout/footer'
import Header5 from '../layout/header5';
import Footer6 from '../layout/footer6';

var bnr3 = require('./../../images/banner/bnr3.jpg');

class About2 extends Component {


    render() {
        return (
            <>
                <Header5/>
                
                <div className="page-content bg-white" style={{textAlign:'justify'}}>
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ paddingTop : 100,backgroundImage: "url(" + bnr3 + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">About us</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to={"/"}>Home</Link></li>
                                        <li>About us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="our-story">
                                            {/* <span>OUR Mission</span> */}
                                            <h2 className="title">About 
                                            {/* <br/>successful business <br/> */}
                                            <br/>
                                            <span className="text-primary">Surge Control Towers</span>
                                            </h2>
                                            {/* <h4 className="title">Industrial engineering is a branch of engineering which deals with the optimization.</h4> */}
                                            <p>Surge Control Towers Global Inc is providing an innovative and patented “Fit and Forget”, Self-Regulating Passive Water Surge Protection System.</p>
                                            <p>Founded in 2023, we are committed to work with waterworks and irrigation industry to design, develop and implement passive surge protection devices and bring clean and potable water to the consumers.</p>
                                            <p>Designing and Building High Quality Passive Water Surge Protection Devices is not just a slogan for us, it is our responsibility and commitment to our clients to provide with the product system which would address the problem of pipeline failure under vacuum, address the malfunction and failure observed in the active surge equipment like special surge protection vessels, air vessels, ZVV etc.</p>
                                            {/* <Link to={"/#"} className="site-button">Read More</Link> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
                                        <img src={require("../../images/about/pic6.jpg")} className="radius-sm" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className="section-full box-about-list">
                            <div className="row spno">
                                <div className="col-lg-6 col-md-12"> 
                                    <img src={require("../../images/about/pic3.jpg")} alt="" className="img-cover"/> 
                                </div>
                                <div className="col-lg-6 col-md-12 bg-primary">
                                    <div className="max-w700 m-auto p-tb50 p-lr20">
                                        <div className="text-white">
                                            <h2>We're thriving and building <br/>better products</h2>
                                        </div>
                                        <div className="icon-bx-wraper m-b30 left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-factory"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Material Engineering</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                            </div>
                                        </div>
                                        <div className="icon-bx-wraper m-b30 left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-settings"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Chemical Research</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                            </div>
                                        </div>
                                        <div className="icon-bx-wraper left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-worker"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Petroleum Engineering</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        
                        {/* <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="title"> Our Services</h2>
                                    <p>There are many variations of passages of Lorem Ipsum typesetting industry has been the industry's standard dummy text ever since the been when an unknown printer.</p>
                                </div>
                                <div className="section-content row">
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="01">
                                            <div className="icon-lg">
                                                <Link to={"/#"} className="icon-cell"><i className="flaticon-robot-arm"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Mechanical Works</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="02">
                                            <div className="icon-lg">
                                                <Link to={"/#"} className="icon-cell"><i className="flaticon-factory-1"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Power & Energy</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="03">
                                            <div className="icon-lg">
                                                <Link to={"/#"} className="icon-cell"><i className="flaticon-fuel-station"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Petroleum Refinery</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="04">
                                            <div className="icon-lg">
                                                <Link to={"/#"} className="icon-cell"><i className="flaticon-fuel-truck"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Oil & Gas Industry</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="05">
                                            <div className="icon-lg">
                                                <Link to={"/#"} className="icon-cell"><i className="flaticon-conveyor-1"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Automative Manufacturing</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="06">
                                            <div className="icon-lg">
                                                <Link to={"/#"} className="icon-cell"><i className="flaticon-engineer-1"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Chemical Research</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        
                        {/* <div className="section-full about-progress">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-7 col-md-12 bg-white">
                                        <div className="max-w700  m-auto content-inner">
                                            <div className="section-head">
                                                <h2 className="title">We provide the exceptional service we'd want to experience ourselves</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer</p>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Web Development<span className="progress-num pull-right">85%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.2s" data-wow-duration="2s" style={{width:"85%"}}></div>
                                                </div>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Web Designer<span className="progress-num pull-right">80%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.4s" data-wow-duration="2s" style={{width:"85%"}}></div>
                                                </div>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Performance<span className="progress-num pull-right">75%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.6s" data-wow-duration="2s" style={{width:"85%"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12 p-a0">
                                        <img src={require("../../images/about/pic5.jpg")} alt="" className="img-cover "/> 
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="section-full bg-white">
                            <div className="container">
                                <div className="row">
                                <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/about/pic3.jpg")}/>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <img alt="" src={require("../../images/our-services/pic2.jpg")}/>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <img alt="" src={require("../../images/our-services/pic3.jpg")}/>
                                            </div>
                                            <div className="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/about/about2.jpg")}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 m-b30">
                                        <h2 className="text-black font-weight-600 m-b15">Global Operations</h2>
                                        <p>We currently have presence in United States of America and India. Design and development work is handled by our water surge expert and dedicated professionals to provide solutions to the water & flow management needs of our clients.</p>
                                        <h2 className="text-black font-weight-600 m-b15">Engineering and Technical Support</h2>
                                        <p>Understanding water surge phenomenon is the key to designing, developing, and manufacturing a comprehensive water surge protection device according to the most stringent quality standards.</p>
                                        <p>We work with our suppliers in India to manufacture passive water surge protection device and provide innovative, reliable, quality, and precision solutions to meet customer needs; and to provide every customer with excellent comprehensive commercial and technical support before, during and after the installation of the device.</p>
                                        
                                        <h2 className="text-black font-weight-600 m-b15">
                                        Commitment to Manage Earth’s Most Precious Resource
                                        </h2>
                                        <p>At Surge Control Towers Global Inc, we understand the need for effective and reliable management of our earth’s most precious resource, which is vital for our own existence.</p>
                                        <p>This underlines our commitment to design, manufacture and supply quality and reliable passive water surge protection systems which will be environmentally friendly and sustainable. Our passive water surge protection device minimizes piping failure under vacuum and provides a system which addresses the malfunction/failure observed in the currently used active surge control systems by waterworks and irrigation companies thereby help in reaping the full benefits of every single drop of water.</p>
                                        
                                        {/* <div className="row widget widget_getintuch widget_getintuch-pro-details m-lr0">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-user"></i>
                                                    <strong>CLIENT</strong> Martin Stewart
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-location-pin"></i>
                                                    <strong>LOCATION</strong>London, UK
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-ruler-alt-2"></i>
                                                    <strong>SIZE</strong>1,200m<sup>2</sup>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-home"></i>
                                                    <strong>TYPE</strong>Residential Project
                                                </div>
                                            </div>
                                        </div>
                                        <h2 className="text-black font-weight-600 m-b15">Scoope Of Work</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an printer took a galley of type and scrambled it to make.</p>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="icon-bx-wraper m-b30 left">
                                                    <div className="icon-md"> 
                                                        <Link to="#" className="icon-cell text-primary">
                                                            <i className="flaticon-factory"></i>
                                                        </Link> 
                                                    </div>
                                                    <div className="icon-content">
                                                        <h5 className="dlab-tilte">Material Engineering</h5>
                                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                                    </div>
                                                </div>
                                                <div className="icon-bx-wraper m-b30 left">
                                                    <div className="icon-md"> 
                                                        <Link to="#" className="icon-cell text-primary">
                                                            <i className="flaticon-settings"></i>
                                                        </Link> 
                                                    </div>
                                                    <div className="icon-content">
                                                        <h5 className="dlab-tilte">Chemical Research</h5>
                                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                                    </div>
                                                </div>
                                                <div className="icon-bx-wraper left">
                                                    <div className="icon-md"> 
                                                        <Link to="#" className="icon-cell text-primary">
                                                            <i className="flaticon-worker"></i>
                                                        </Link> 
                                                    </div>
                                                    <div className="icon-content">
                                                        <h5 className="dlab-tilte">Petroleum Engineering</h5>
                                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>
                </div>
                <Footer6/>
            </>
        )
    }
}
export default About2;