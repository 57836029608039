import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header5";
import Footer from "../layout/footer5";
import Testmonial3 from "../element/testmonial3";
import { blogContent3, homeSliderContent5 } from "../element/SliderContent";
import BlogSlider1 from "../element/blogSlider1";
import ProjectSlider5 from "../element/projectSlider5";
import ProjectSlider7 from "../element/projectSlider7";
import HomeSlider from "../element/homeslider1";
import Gallery3 from "../element/gallery3";
import ScrollToTop from "../element/scrollToTop";
import Team5 from "../element/team5";
import Footer6 from "../layout/footer6";

// import '../../css/skin/skin-3.min.css';

const bg = require("../../images/background/bg11.jpg");
const bg2 = require("../../images/background/bg9.jpg");
const bg3 = require("../../images/background/map-bg1.png");

const images = [
  {
    id: 1,
    name: "Construction",
    icon: "flaticon-worker",
    title: "Solid Wastes",
    title2: "Westwood Child Center",
    imgUrl: require("../../images/portfolio/construct/image_1.jpg"),
  },
  {
    id: 1,
    name: "Construction",
    icon: "flaticon-worker",
    title: "Solid Wastes",
    title2: "Westwood Child Center",
    imgUrl: require("../../images/portfolio/construct/image_2.jpg"),
  },
  {
    id: 1,
    name: "Construction",
    icon: "flaticon-worker",
    title: "Solid Wastes",
    title2: "Westwood Child Center",
    imgUrl: require("../../images/portfolio/construct/image_3.jpg"),
  },
  {
    id: 1,
    name: "Construction",
    icon: "flaticon-worker",
    title: "Solid Wastes",
    title2: "Westwood Child Center",
    imgUrl: require("../../images/portfolio/construct/image_4.jpg"),
  },
  {
    id: 1,
    name: "Construction",
    icon: "flaticon-worker",
    title: "Solid Wastes",
    title2: "Westwood Child Center",
    imgUrl: require("../../images/portfolio/construct/image_5.jpg"),
  },
  {
    id: 1,
    name: "Construction",
    icon: "flaticon-worker",
    title: "Solid Wastes",
    title2: "Westwood Child Center",
    imgUrl: require("../../images/portfolio/construct/image_6.jpg"),
  },
  {
    id: 2,
    name: "Architecture",
    icon: "flaticon-crane",
    title: "Leather Products",
    imgUrl: require("../../images/portfolio/construct/image_2.jpg"),
  },
  {
    id: 2,
    name: "Architecture",
    icon: "flaticon-crane",
    title: "Leather Products",
    imgUrl: require("../../images/portfolio/construct/image_4.jpg"),
  },
  {
    id: 2,
    name: "Architecture",
    icon: "flaticon-crane",
    title: "Leather Products",
    imgUrl: require("../../images/portfolio/construct/image_2.jpg"),
  },
  {
    id: 3,
    name: "Consulting",
    icon: "flaticon-analytics",
    title: "Leather processing",
    imgUrl: require("../../images/portfolio/construct/image_3.jpg"),
  },
  {
    id: 3,
    name: "Consulting",
    icon: "flaticon-analytics",
    title: "Leather processing",
    imgUrl: require("../../images/portfolio/construct/image_5.jpg"),
  },
  {
    id: 4,
    name: "Mechanical",
    icon: "flaticon-optimization",
    title: "Industrial Apartment",
    imgUrl: require("../../images/portfolio/construct/image_4.jpg"),
  },
];

class Index5 extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white home-slider-5">
          <HomeSlider
            data={homeSliderContent5}
            contentWrapperCls="sliderStyle5"
            btnCls="site-button"
            btnText="ABOUT US"
            btn2Text="SERVICES"
            show2Btn
          />

          <div className="content-block">
            
            <div className="section-full bg-light content-inner">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12 col-sm-12">
                    <div className="mission-statement text-center">
                      <h2 className="title">Our Mission</h2>
                      <p>
                        We, at Surge Control Towers Global Inc, are committed to
                        providing robust Water Surge Protection technology which
                        addresses Down surges and Up surges, ensuring a seamless
                        flow of water to consumers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-full content-inner const-about">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 col-12 wow fadeInLeft"
                    data-wow-duration="2s"
                    data-wow-delay="0.3s"
                  >
                    <div className="radius-sm m-b30 img-ho1">
                      <img
                        src={require("../../images/about/pic11.jpg")}
                        alt="Surge Control Tower"
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 col-12 wow fadeInRight"
                    data-wow-duration="2s"
                    data-wow-delay="0.6s"
                  >
                    <div className="content-bx1">
                      {/* <div className="about-year">
                        <span>26</span>
                        <p>Years<br/>Experience<br/>in Water Management</p>
                    </div> */}
                      <div className="section-head style2">
                        <h2 className="title">
                          Surge Control Towers: <br /> Essential for Pressure
                          Management
                        </h2>
                        <p>
                          Surge control towers play a crucial role in managing
                          pressure fluctuations in water supply systems,
                          irrigation networks, and hydropower plants. They
                          prevent damage caused by water hammer, ensure
                          consistent water flow, and protect infrastructure.
                        </p>
                        <p>
                          With decades of experience, we specialize in designing
                          and implementing effective surge control solutions to
                          enhance the reliability and safety of your water
                          management systems.
                        </p>
                      </div>
                      {/* <Link to="/portfolio-details" className="site-button m-r10 m-b10 btnhover20">View Projects</Link> */}
                      {/* <Link to="/about-1" className="site-button black m-b10 btnhover20">Learn More</Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="section-full bg-white content-inner-2">
              <div class="container">
                <div class="row">
                

                  
                  <div
                    class="col-lg-4 col-md-6 col-sm-6 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.3s"
                  >
                    <div class="icon-bx-wraper sr-iconbox style2">
                      <div class="icon-lg m-b10">
                      <Link to="#" className="icon-cell">
                          <i class="flaticon-worker"></i>
                        </Link>
                      </div>
                      <div class="icon-content">
                        <h4 class="dlab-tilte">Pressure Regulation</h4>
                        <p>
                          Surge control towers absorb excess pressure in the
                          system, preventing damage to pipelines and equipment.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-4 col-md-6 col-sm-6 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.6s"
                  >
                    <div class="icon-bx-wraper sr-iconbox style2">
                      <div class="icon-lg m-b10">
                      <Link to="#" className="icon-cell">
                          <i class="flaticon-factory-1"></i>
                        </Link>
                      </div>
                      <div class="icon-content">
                        <h4 class="dlab-tilte">Flow Stabilization</h4>
                        <p>
                          They help stabilize the flow of water within the
                          system, ensuring a consistent and reliable supply.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-4 col-md-6 col-sm-6 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.9s"
                  >
                    <div class="icon-bx-wraper sr-iconbox style2">
                      <div class="icon-lg m-b10">
                      <Link to="#" className="icon-cell">
                          <i class="flaticon-design-tools"></i>
                        </Link>
                      </div>
                      <div class="icon-content">
                        <h4 class="dlab-tilte">Protection</h4>
                        <p>
                          By mitigating the effects of water hammer, surge
                          control towers protect infrastructure from the
                          stresses caused by rapid pressure changes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-4 col-md-6 col-sm-6 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.3s"
                  >
                    <div class="icon-bx-wraper sr-iconbox style2">
                      <div class="icon-lg m-b10">
                      <Link to="#" className="icon-cell">
                          <i class="flaticon-settings"></i>
                        </Link>
                      </div>
                      <div class="icon-content">
                        <h4 class="dlab-tilte">
                          Application in Water Supply Systems
                        </h4>
                        <p>
                          To ensure a steady supply and protect pipes from
                          pressure surges in water supply systems.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-4 col-md-6 col-sm-6 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.6s"
                  >
                    <div class="icon-bx-wraper sr-iconbox style2">
                      <div class="icon-lg m-b10">
                      <Link to="#" className="icon-cell">
                          <i class="flaticon-worker"></i>
                        </Link>
                      </div>
                      <div class="icon-content">
                        <h4 class="dlab-tilte">
                          Application in Irrigation Systems
                        </h4>
                        <p>
                          To maintain consistent flow rates and prevent damage
                          to irrigation equipment.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-4 col-md-6 col-sm-6 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.9s"
                  >
                    <div class="icon-bx-wraper sr-iconbox style2">
                      <div class="icon-lg m-b10">
                      <Link to="#" className="icon-cell">
                          <i class="flaticon-factory-2"></i>
                        </Link>
                      </div>
                      <div class="icon-content">
                        <h4 class="dlab-tilte">
                          Application in Hydropower Plants
                        </h4>
                        <p>
                          To manage the pressures in penstocks and other
                          conduits, ensuring efficient and safe operation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-head style2 text-center">
                                <h2 className="title">Our Team</h2>
                                <p>There are many variations of passages of Lorem Ipsum typesetting industry has been the industry's standard dummy text ever since the been when an unknown printer.</p>
                            </div>
                            <Team5 />
                        </div>
                    </div> */}

            {/* <div className="section-full content-inner-2 bg-white wow fadeIn" style={{ backgroundImage: "url(" + bg3 + ")" }} data-wow-duration="2s" data-wow-delay="0.6s">
                        <div className="container">
                            <div className="section-head style2 text-center">
                                <h2 className="title">Clients Say About Us</h2>
                                <p>19+ years’ experience</p>
                            </div>
                            <Testmonial3/>
                        </div>
                    </div> */}

            {/* <div className="section-full content-inner bg-gray wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.9s">
                        <div className="container">
                            <div className="section-head style2 text-center">
                                <h2 className="title">Latest blog post</h2>
                                <p>There are many variations of passages of Lorem Ipsum typesetting industry has been the industry's standard dummy text ever since the been when an unknown printer.</p>
                            </div>

                            <BlogSlider1
                            data={blogContent3} 
                            imgEffect="zoom"
                            blogPostCls="post-style-2"
                            noDes
                            />
                            
                        </div>
                    </div> */}
          </div>
        </div>
        <Footer6 />
        <ScrollToTop className="style2 radius" />
      </>
    );
  }
}
export default Index5;
